<template>
  <div>
    <header-main
        subtitle=""
        title="Заполнение контактных данных"
    />
    <div class="sort-options">
      <span class="sort-title">Сортировать по:</span>
      <div class="sort-item" @click="sortType = 'fio'">ФИО</div>
      <div class="sort-item" @click="sortType = 'position'">Должность</div>
      <div class="sort-item" @click="sortType = 'email'">E-mail</div>
      <div class="sort-item" @click="sortType = 'workPhoneNumber'">Номер рабочего телефона</div>
      <div class="sort-item" @click="sortType = 'extensionNumber'">Добавочный номер</div>
      <div class="sort-item" @click="sortType = 'personalPhoneNumber'">Номер мобильного телефона</div>
    </div>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <WrapperCollapse
          :initialOpen="true"
          :title="'Проректор, курирующий учебную(учебно-методическую) деятельность'"
      >
        <ContainerPersonContact
            :blocked="getBlock"
            :can-be-deleted="false"
            :contact="contacts.contact1"
            :loading-contacts="savingContact1"
            :saving-data="savingContact1"
            @change-contact="changeContact($event,0,contacts.contact1)"
            is-single
        />
      </WrapperCollapse>
    </WrapperPreviewCategory>
    <div>
      <WrapperPreviewCategory customClass="section-preview_lk">
        <WrapperCollapse
                :initialOpen="true"
                :title="'Проректор курирующий вопросы международной деятельности '"
        >
          <ContainerPersonContact
                  :blocked="getBlock"
                  :can-be-deleted="false"
                  :contact="contacts.contacts41"
                  :loading-contacts="loadingContacts41"
                  :saving-data="savingContact41"
                  @change-contact="changeContact($event, 0, contacts.contacts41)"
                  is-single
          >
            <template #addContactText>Добавить ответственного за международную деятельность</template>
          </ContainerPersonContact>
        </WrapperCollapse>
      </WrapperPreviewCategory>
    </div>
    <div>
      <WrapperPreviewCategory :key="zindex" customClass="section-preview_lk"
                              v-for="(zitem,zindex) in sortedContacts.contacts4">
        <WrapperCollapse
            :initialOpen="true"
            :title="'ОТВЕТСТВЕННЫЙ ЗА МЕЖДУНАРОДНУЮ ДЕЯТЕЛЬНОСТЬ'"
        >
          <ContainerPersonContact
              :blocked="getBlock"
              :can-be-deleted="zindex!==0"
              :is-single="contacts.contacts4.length > 1"
              :contact="zitem"
              :loading-contacts="loadingContacts4"
              :saving-data="savingContact4"
              @add-contact="addContact(zindex, contacts.contacts4)"
              @change-contact="changeContact($event, zindex, contacts.contacts4)"
              @delete-contact="deleteContact($event, zindex, contacts.contacts4)"
          >
            <template #addContactText>Добавить ответственного за международную деятельность
            </template>
          </ContainerPersonContact>
        </WrapperCollapse>
      </WrapperPreviewCategory>
    </div>
    <div>
      <WrapperPreviewCategory :key="xindex" customClass="section-preview_lk"
                              v-for="(xitem,xindex) in sortedContacts.contacts5">
        <WrapperCollapse
            :initialOpen="true"
            :title="'Проректор, курирующий вопросы инновационного развития'"
        >
          <ContainerPersonContact
              :blocked="getBlock"
              :can-be-deleted="false"
              :contact="xitem"
              :loading-contacts="loadingContacts5"
              :saving-data="savingContact5"
              @add-contact="addContact(xindex, contacts.contacts5)"
              @change-contact="changeContact($event, xindex, contacts.contacts5)"
              @delete-contact="deleteContact($event, xindex, contacts.contacts5)"
              is-single
          />
        </WrapperCollapse>
      </WrapperPreviewCategory>
    </div>
    <div>
      <WrapperPreviewCategory :key="index" customClass="section-preview_lk"
                              v-for="(item,index) in sortedContacts.contacts6">
        <WrapperCollapse
            :initialOpen="true"
            :title="'Проректор, курирующий вопросы приемной кампании'"
        >
          <ContainerPersonContact
              :blocked="getBlock"
              :can-be-deleted="false"
              :contact="item"
              :loading-contacts="loadingContacts6"
              :saving-data="savingContact6"
              @change-contact="changeContact($event, index, contacts.contacts6)"
              @delete-contact="deleteContact($event, index, contacts.contacts6)"
              is-single
          />
        </WrapperCollapse>
      </WrapperPreviewCategory>
    </div>
    <div>
      <WrapperPreviewCategory :key="jindex" customClass="section-preview_lk"
                              v-for="(jitem,jindex) in sortedContacts.contacts3">
        <WrapperCollapse
            :initialOpen="true"
            :title="'Ответственный по работе в системе КЦП-ВО'"
        >
          <ContainerPersonContact
              :blocked="getBlock"
              :can-be-deleted="false"
              :contact="jitem"
              :loading-contacts="loadingContacts3"
              :saving-data="savingContact3"
              @change-contact="changeContact($event, jindex, contacts.contacts3)"
              @delete-contact="deleteContact($event, jindex, contacts.contacts3)"
              is-single
          />
        </WrapperCollapse>
      </WrapperPreviewCategory>
    </div>
    <div>
      <WrapperPreviewCategory :key="index" customClass="section-preview_lk"
                              v-for="(item,index) in sortedContacts.contacts2">
        <WrapperCollapse
            :initialOpen="true"
            :title="'Ответственный секретарь приемной комиссии ' +(index+1)"
        >
          <ContainerPersonContact
              :blocked="getBlock"
              :can-be-deleted="index!==0"
              :is-single="contacts.contacts2.length > 1"
              :contact="item"
              :loading-contacts="loadingContacts2"
              :saving-data="savingContact2"
              @add-contact="addContact(index, contacts.contacts2)"
              @change-contact="changeContact($event, index, contacts.contacts2)"
              @delete-contact="deleteContact($event, index, contacts.contacts2)"
          />
        </WrapperCollapse>
      </WrapperPreviewCategory>
    </div>

    <Alert alert-type="success" v-if="!getBlock">
      <template #icon>
        <v-icon color="#29A128" size="40">mdi-check-bold</v-icon>
      </template>
      <template #default>
        <div class="d-flex flex-row justify-space-between">
          <p class="d-flex align-center">{{
              getBlock ? 'Ввод данных заблокирован' :
                  'Заблокировать ввод данных'
            }}</p>
          <v-btn
              :disabled="getBlock"
              :loading="loadingBlockEnterData"
              @click="clickBlockEnterData()"
              class="success__v-btn pa-3"
              color="#29A128"
              v-if="!getBlock"
          >
            <span style="color:#fff;">{{ getBlock ? 'Заблокировано' : 'Заблокировать ввод' }}</span>
          </v-btn>
        </div>
      </template>
    </Alert>

  </div>
</template>

<script>
import request from "@/services/request";
import {mapActions, mapGetters} from 'vuex';
import HeaderMain from "@/components/headers/HeaderMain";
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';
import ContainerPersonContact from "@/components/contacts/ContainerPersonContact";
import Alert from "@/components/UI/Alert";

export default {
  name: "LK",
  components: {
    Alert,
    HeaderMain,
    WrapperPreviewCategory,
    WrapperCollapse,
    ContainerPersonContact,
  },
  data() {
    return {
      contacts: {
        contact1:
            {
              fio: '',
              email: '',
              position: '',
              workPhoneNumber: '',
              personalPhoneNumber: '',
              extensionNumber: '',
              personType: 'HEAD'
            },
        contacts2: [
          {
            fio: '',
            email: '',
            position: '',
            workPhoneNumber: '',
            personalPhoneNumber: '',
            extensionNumber: '',
            personType: 'SECRETARY'
          },
        ],
        contacts3: [
          {
            fio: '',
            email: '',
            position: '',
            workPhoneNumber: '',
            personalPhoneNumber: '',
            extensionNumber: '',
            personType: 'KCP_VO'
          },
        ],
        contacts41:
          {
            fio: '',
            email: '',
            position: '',
            workPhoneNumber: '',
            personalPhoneNumber: '',
            extensionNumber: '',
            personType: 'INTERNATIONAL_ACTIVITIES_DIRECTOR',
          },
        contacts4: [
          {
            fio: '',
            email: '',
            position: '',
            workPhoneNumber: '',
            personalPhoneNumber: '',
            extensionNumber: '',
            personType: 'INTERNATIONAL_ACTIVITIES',
          },
        ],
        contacts5: [
          {
            fio: '',
            email: '',
            position: '',
            workPhoneNumber: '',
            personalPhoneNumber: '',
            extensionNumber: '',
            personType: 'INNOVATIVE_DEVELOPMENT',
          },
        ],
        contacts6: [
          {
            fio: '',
            email: '',
            position: '',
            workPhoneNumber: '',
            personalPhoneNumber: '',
            extensionNumber: '',
            personType: 'PROREKTOR_SECRETARY',
          },
        ],
      },
      loadingContacts: false,
      savingContact1: false,
      loadingContacts1: false,
      errorSaving1: null,
      savingContact2: false,
      loadingContacts2: false,
      errorSaving2: null,
      savingContact3: false,
      loadingContacts3: false,
      errorSaving3: null,
      savingContact41: false,
      loadingContacts41: false,
      savingContact4: false,
      loadingContacts4: false,
      savingContact5: false,
      loadingContacts5: false,
      savingContact6: false,
      loadingContacts6: false,
      loadingBlockEnterData: false,
      loadingBlockEnterWithoutData: false,
      downloadingFileForPrint: false,
      sortType: 'fio',
    }
  },
  created() {
    if (this.authData && this.orgID)
      this.getUserInfo()
    this.getContacts()
  },
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    ...mapGetters('auth', {authToken: 'authToken'}),
    ...mapGetters('auth', {authStatus: 'authStatus'}),
    ...mapGetters('checkFilled', {get_check_filled: 'get_check_filled'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
    ...mapGetters('auth', {getAuthUserData: 'getAuthUserData'}),
    ...mapGetters('auth', {getAuthUserDataLoading: 'getAuthUserDataLoading'}),
    ...mapGetters('auth', {getAuthUserDataError: 'getAuthUserDataError'}),
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),

    getBlock() {
      if (this.$route.query?.orgID) {
        return true;
      }
      return this.getAuthUserData?.blocked;
    },
    orgID() {
      // if (this.$route.query?.orgID) {
      //   return this.$route.query.orgID;
      // }
      // return Number.parseInt(this.authData?.ordID ?? this.authData?.family_name);
      // const orgId =
      return this.getAuthData.idTokenParsed.orgID
    },
    sortedContacts() {
      const sortedContacts = {...this.contacts}
      sortedContacts.contacts2?.sort((a, b) => a[this.sortType] > b[this.sortType] ? 1 : -1)
      sortedContacts.contacts3?.sort((a, b) => a[this.sortType] > b[this.sortType] ? 1 : -1)
      sortedContacts.contacts4?.sort((a, b) => a[this.sortType] > b[this.sortType] ? 1 : -1)
      sortedContacts.contacts5?.sort((a, b) => a[this.sortType] > b[this.sortType] ? 1 : -1)
      sortedContacts.contacts6?.sort((a, b) => a[this.sortType] > b[this.sortType] ? 1 : -1)
      return sortedContacts
    },
    getOrgFounderID() {
      return Number.parseInt(this.getAuthUserData?.founderId);
    },
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    ...mapActions('auth', {blockEnterData: 'blockEnterData'}),
    ...mapActions('auth', {getUserInfo: 'getUserInfo'}),
    ...mapActions('auth', {blockOrganization: 'blockOrganization'}),
    getQuery(item) {
      return {
        path: item.href,
        query: {orgID: this.$route.query.orgID}
      }
    },
    async clickBlockEnterData() {
      this.loadingContacts = true;
      const res = await request({
        endpoint: `/api/directory/organization/${this.orgID}/block`,
        method: 'get'
      })
      if (res.status === 200) {
        this.blockOrganization(res.data)
      }
      this.loadingContacts = false;
    },
    async getContacts() {
      this.loadingContacts = true;
      const res = await request({
        endpoint: `/api/contact/${this.orgID}`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data.length > 0) {
          const bufResSecretary = res.data.filter(el => el.personType === 'SECRETARY');
          const bufResKcp = res.data.filter(el => el.personType === 'KCP_VO');
          const bufResInternational = res.data.filter(el => el.personType === 'INTERNATIONAL_ACTIVITIES');
          const bufResInnovative = res.data.filter(el => el.personType === 'INNOVATIVE_DEVELOPMENT');
          const bufResProSecretary = res.data.filter(el => el.personType === 'PROREKTOR_SECRETARY');
          this.contacts.contact1 = res.data.find(el => el.personType === 'HEAD')
          this.contacts.contacts2 = bufResSecretary.length > 0 ? bufResSecretary : this.contacts.contacts2;
          this.contacts.contacts3 = bufResKcp.length > 0 ? bufResKcp : this.contacts.contacts3;
          this.contacts.contacts41 = res.data.find(el => el.personType === 'INTERNATIONAL_ACTIVITIES_DIRECTOR');
          this.contacts.contacts4 = bufResInternational.length > 0 ? bufResInternational : this.contacts.contacts4;
          this.contacts.contacts5 = bufResInnovative.length > 0 ? bufResInnovative : this.contacts.contacts5;
          this.contacts.contacts6 = bufResProSecretary.length > 0 ? bufResProSecretary : this.contacts.contacts6;
          await this.fetchCheckFilled({
            headContact: this.contacts.contact1,
            contacts: this.contacts.contact1
          })
        }
      }
      this.loadingContacts = false;
    },
    async deleteContact(obj, index, item) {
      let deleteTarget;
      let savingContact;
      switch (item) {
        case this.contacts.contact1:
          deleteTarget = this.contacts.contact1;
          savingContact = this.savingContact1;
          break;
        case this.contacts.contacts2:
          deleteTarget = this.contacts.contacts2;
          savingContact = this.savingContact2;
          break;
        case this.contacts.contacts3:
          deleteTarget = this.contacts.contacts3;
          savingContact = this.savingContact3;
          break;
        case this.contacts.contacts41:
          deleteTarget = this.contacts.contacts41;
          savingContact = this.savingContact41;
          break;
        case this.contacts.contacts4:
          deleteTarget = this.contacts.contacts4;
          savingContact = this.savingContact4;
          break;
        case this.contacts.contacts5:
          deleteTarget = this.contacts.contacts5;
          savingContact = this.savingContact5;
          break;
        case this.contacts.contacts6:
          deleteTarget = this.contacts.contacts6;
          savingContact = this.savingContact6;
          break;
      }
      if (obj?.id) {
        const res = await request({
          endpoint: `/api/contact/delete?contactID=${obj.id}`,
          method: 'delete'
        });
        if (res.status === 200) {
          console.log(deleteTarget.splice(index, 1), savingContact)
        } else {
          savingContact = false
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка сохранения данных ответственного ',
            timeout: 5000
          })
        }
      } else {
        deleteTarget.splice(index, 1)
      }
    },
    addContact(index, contacts) {
      let personType;
      switch (contacts) {
        case this.contacts.contact1:
          personType = 'MAIN';
          break;
        case this.contacts.contacts2:
          personType = 'SECRETARY';
          break;
        case this.contacts.contacts3:
          personType = 'KCP_VO';
          break;
        case this.contacts.contacts41:
          personType = 'INTERNATIONAL_ACTIVITIES_DIRECTOR';
          break;
        case this.contacts.contacts4:
          personType = 'INTERNATIONAL_ACTIVITIES';
          break;
        case this.contacts.contacts5:
          personType = 'INNOVATIVE_DEVELOPMENT';
          break;
        case this.contacts.contacts6:
          personType = 'PROREKTOR_SECRETARY';
          break;
      }
      contacts.splice(index + 1, 0, {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
        extensionNumber: '',
        personType: personType,
      })
    },
    async changeContact(obj, index, contacts) {
      let personType;
      let savingContact;
      let changeTarget;
      switch (contacts) {
        case this.contacts.contact1:
          changeTarget = this.contacts.contact1;
          personType = 'HEAD';
          savingContact = this['savingContact1'];
          break;
        case this.contacts.contacts2:
          changeTarget = this.contacts.contacts2;
          personType = 'SECRETARY';
          savingContact = this['savingContact2'];
          break;
        case this.contacts.contacts3:
          changeTarget = this.contacts.contacts3;
          personType = 'KCP_VO';
          savingContact = this['savingContact3'];
          break;
        case this.contacts.contacts41:
          changeTarget = this.contacts.contacts41;
          personType = 'INTERNATIONAL_ACTIVITIES_DIRECTOR';
          savingContact = this['savingContact41'];
          break;
        case this.contacts.contacts4:
          changeTarget = this.contacts.contacts4;
          personType = 'INTERNATIONAL_ACTIVITIES';
          savingContact = this['savingContact4'];
          break;
        case this.contacts.contacts5:
          changeTarget = this.contacts.contacts5;
          personType = 'INNOVATIVE_DEVELOPMENT';
          savingContact = this['savingContact5'];
          break;
        case this.contacts.contacts6:
          changeTarget = this.contacts.contacts6;
          personType = 'PROREKTOR_SECRETARY';
          savingContact = this['savingContact6'];
          break;
      }

      savingContact = true;

      console.log(changeTarget, obj, this.orgID, personType);
      const res = await request({
        endpoint: `/api/contact/${obj?.id ? 'update' : 'new'}`,
        method: obj?.id ? 'put' : 'post',
        body: {
          ...obj,
          orgId: this.orgID,
          personType: personType
        },
      });
      if (res.status === 200) {
        console.log(savingContact);
        if (this.contacts.contact1 === changeTarget)
          this.contacts.contact1 = res.data;
        else if (this.contacts.contacts41 === changeTarget)
          this.contacts.contacts41 = res.data;
        else
          changeTarget.splice(index, 1, res.data);
        setTimeout(() => {
          savingContact = false;
        }, 2000);
      } else {
        savingContact = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного ',
          timeout: 5000
        })
      }
    },
  }
}
</script>

<style lang="sass">
.section-preview_lk
  background: #fff
  border-radius: 6px
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06)
  //border: 1px solid #cdd1d4
  margin-bottom: 20px


  .lk-list_flex_start
    justify-content: flex-start


.sort-options
  display: flex
  justify-content: flex-start
  align-items: center
  margin-bottom: 40px

.sort-item
  margin-right: 20px
  padding: 5px 10px
  color: #00599B
  font-weight: bold
  border: 1px solid #00599B
  border-radius: 10px
  cursor: pointer !important

  &:hover
    background-color: #ffffff

.sort-title
  margin-right: 30px

</style>
