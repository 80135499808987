<template>
  <WrapperPreviewCategory customClass="section-preview_lk">
    <v-row>
      <v-col class=" d-flex justify-end">
        <card-prin-document-btn
                link="/api/export/query1"
                name="Выгрузка"
                class="mr-2 mb-2"
                file-type="xlsx"
                file-type-name=".xlsx"
                type="blue"
        >
        </card-prin-document-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            v-model="orgFilter"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
        :headers="getHeaders"
        :items="getOrgList"
        :search="orgFilter"
        :custom-filter="filterOrg"
        item-key="userID"
        class="mt-5"
        :loading="loadingOrgContacts"
    >
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.orgId">
          <td>{{ item.orgId }}</td>
          <td>
            {{ item.organizationName }}
          </td>
          <td>
            {{item.blocked ? 'Заблокировался':'Не заблокирован' }}
          </td>

          <td class="">
            <v-btn
                class="mt-2 blue__v-btn"
                @click="clickShowDialog(item)"
            >
              Контакты
            </v-btn>
          </td>
          <td class="">
            <v-btn  v-if="item.blocked"
                    class="mt-2 blue__v-btn"
                    @click="clickUnblock(item)"
            >
              Разблокировать
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-dialog v-model="showDialog" max-width="1100px">
      <v-card>
        <v-card-text>Контакты организации</v-card-text>
        <v-card-text>
          <WrapperPreviewCategory customClass="section-preview_lk">
            <WrapperCollapse
                :initialOpen="true"
                :title="'Проректор, курирующий учебную(учебно-методическую) деятельность'"
            >
              <ContainerPersonContact
                  :blocked="true"
                  :can-be-deleted="false"
                  :contact="contact1"
                  is-single
              />
            </WrapperCollapse>
          </WrapperPreviewCategory>
          <div>
            <WrapperPreviewCategory :key="zindex" customClass="section-preview_lk" v-for="(zitem,zindex) in contacts4">
              <WrapperCollapse
                  :initialOpen="true"
                  :title="zindex === 0 ? 'Проректор курирующий вопросы международной деятельности ' :  'ОТВЕТСТВЕННЫЙ ЗА МЕЖДУНАРОДНУЮ ДЕЯТЕЛЬНОСТЬ'"
              >
                <ContainerPersonContact
                    :blocked="true"
                    :can-be-deleted="zindex!==0"
                    :is-single="contacts4.length > 1"
                    :contact="zitem"
                >
                  <template #addContactText>Добавить ответственного за международную деятельность</template>
                </ContainerPersonContact>
              </WrapperCollapse>
            </WrapperPreviewCategory>
          </div>
          <div>
            <WrapperPreviewCategory :key="xindex" customClass="section-preview_lk" v-for="(xitem,xindex) in contacts5">
              <WrapperCollapse
                  :initialOpen="true"
                  :title="'Проректор, курирующий вопросы инновационного развития'"
              >
                <ContainerPersonContact
                    :blocked="true"
                    :can-be-deleted="false"
                    :contact="xitem"
                    is-single
                />
              </WrapperCollapse>
            </WrapperPreviewCategory>
          </div>
          <div>
            <WrapperPreviewCategory :key="index" customClass="section-preview_lk" v-for="(item,index) in contacts6">
              <WrapperCollapse
                  :initialOpen="true"
                  :title="'Проректор, курирующий вопросы приемной кампании'"
              >
                <ContainerPersonContact
                    :blocked="true"
                    :can-be-deleted="false"
                    :contact="item"
                    is-single
                />
              </WrapperCollapse>
            </WrapperPreviewCategory>
          </div>
          <div>
            <WrapperPreviewCategory :key="jindex" customClass="section-preview_lk" v-for="(jitem,jindex) in contacts3">
              <WrapperCollapse
                  :initialOpen="true"
                  :title="'Ответственный по работе в системе КЦП-ВО'"
              >
                <ContainerPersonContact
                    :blocked="true"
                    :can-be-deleted="false"
                    :contact="jitem"
                    is-single
                />
              </WrapperCollapse>
            </WrapperPreviewCategory>
          </div>
          <div>
            <WrapperPreviewCategory :key="index" customClass="section-preview_lk" v-for="(item,index) in contacts2">
              <WrapperCollapse
                  :initialOpen="true"
                  :title="'Ответственный секретарь приемной комиссии ' +(index+1)"
              >
                <ContainerPersonContact
                    :blocked="true"
                    :can-be-deleted="index!==0"
                    :is-single="contacts2.length > 1"
                    :contact="item"
                />
              </WrapperCollapse>
            </WrapperPreviewCategory>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </WrapperPreviewCategory>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import request from "@/services/request";
import {saveFile} from "@/utils/saveFile";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import WrapperCollapse from "@/components/wrappers/WrapperCollapse";
import ContainerPersonContact from "@/components/contacts/ContainerPersonContact";
import CardPrinDocumentBtn from "@/components/cardPrinDocumentBtn";

export default {
  name: "LKAdmin",
  components: {ContainerPersonContact, WrapperCollapse, WrapperPreviewCategory,CardPrinDocumentBtn},
  data: () => ({
    uploadedFileLoading: false,
    exportFileLoading: false,
    loadingBlockEnterData: false,
    orgFilter: null,
    orgContacts: [
      // {name: 'asda', ordID: 1, contact1: {}, contacts2: [], contacts3: []}
    ],
    loadingOrgContacts: false,
    showDialog: false,
    contact1:
        {
          fio: '',
          email: '',
          position: '',
          workPhoneNumber: '',
          personalPhoneNumber: '',
          extensionNumber: '',
          personType: 'HEAD'
        },
    contacts2: [
      {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
        extensionNumber: '',
        personType: 'SECRETARY'
      },
    ],
    contacts3: [
      {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        extensionNumber: '',
        personalPhoneNumber: '',
        personType: 'KCP_VO'
      },
    ],
    contacts4: [
      {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
        extensionNumber: '',
        personType: 'INTERNATIONAL_ACTIVITIES',
      },
    ],
    contacts5: [
      {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
        extensionNumber: '',
        personType: 'INNOVATIVE_DEVELOPMENT',
      },
    ],
    contacts6: [
      {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
        extensionNumber: '',
        personType: 'PROREKTOR_SECRETARY',
      },
    ],
  }),
  computed: {
    ...mapGetters('spr', {getSprDataOrg: 'get_spr_data_org'}),
    getHeaders() {
      return [
        {text: 'ИД', align: 'center', filterable: true, value: 'orgId'},
        {text: 'Наименование ораганизации', filterable: true, value: 'organizationName'},
        {text: 'Статус', filterable: true},
        {text: 'Контакты', filterable: false},
      ]
    },
    getOrgList() {
      let orgContacts = this.orgContacts;
      if (orgContacts !== null) {
        orgContacts = orgContacts.filter((el) => (el?.orgId !== null));
        orgContacts.sort(
            (a, b) => (a.orgId - b.orgId)
        );
      }
      return orgContacts;
    }
  },
  created() {
    this.getUserContacts();
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    clickShowDialog(item) {
      this.showDialog = true;
      // console.log("item", item)
      this.contact1  = item.contact1
      this.contacts2 = item.contacts2
      this.contacts3 = item.contacts3
      this.contacts4 = item.contacts4
      this.contacts5 = item.contacts5
      this.contacts6 = item.contacts6
    },
    filterOrg(value, orgFilter) {
      return value != null &&
          orgFilter != null &&
          typeof value === 'string' &&
          value.toLowerCase().indexOf(orgFilter.toLowerCase()) !== -1
    },
    async getUploadedFile(file) {
      this.uploadedFileLoading = true;
      const response = await request({
        endpoint: `/api/file/scan/download?fileID=${file.fileID}`,
        method: 'GET',
      });
      this.uploadedFileLoading = false;
      saveFile(response.data, false);
    },
    async getExportFile() {
      this.exportFileLoading = true;
      const response = await request({
        endpoint: `/api/export/export`,
        method: 'GET',
      });
      this.exportFileLoading = false;
      saveFile(response.data, false);
    },
    getStatus(userStatus) {
      switch (userStatus) {
        case 'ACTIVE':
          return "Открыт";
        case 'BLOCK_WITHOUT_DATA':
          return "Блок, отсутствие данных";
        case 'BLOCKED_FOR_PRINT':
          return "Блок, ввод закончен"
        default:
          return "Ошибка";
      }
    },
    async getUserContacts() {
      this.loadingOrgContacts = true;
      const res = await request({
        endpoint: `/api/contact/admin/all`,
        method: 'get',
      })
      if (res.status === 200) {
        this.loadingOrgContacts = false
        const bufData = res.data;
        // console.log(bufData)
        const unicsOrgs = new Set(bufData.map(el => {
          return el.orgId
        }))
        // console.log(unicsOrgs)
        unicsOrgs.forEach(el => {
          // console.log("u map", el)
          const searchRes = bufData.filter(el2 => el2.orgId === el)
          this.orgContacts.push({
            orgId: el,
            organizationName: searchRes[0].organizationName,
            blocked: searchRes[0].blocked,
            contact1:  searchRes.find(el3 => el3.personType === 'HEAD'),
            contacts2: searchRes.filter(el4 => el4.personType === 'SECRETARY'),
            contacts3: searchRes.filter(el5 => el5.personType === 'KCP_VO'),
            contacts4: searchRes.filter(el4 => el4.personType === 'INTERNATIONAL_ACTIVITIES'),
            contacts5: searchRes.filter(el4 => el4.personType === 'INNOVATIVE_DEVELOPMENT'),
            contacts6: searchRes.filter(el4 => el4.personType === 'PROREKTOR_SECRETARY'),
          })
        })
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка загрузки заполненных контактов',
          timeout: 5000
        })
        this.loadingOrgContacts = false
      }
    },
    async clickUnblock(item) {
      this.loadingBlockEnterData = true;
      const agree = confirm('Разблокировать ввод?');
      if (agree) {
        const res = await request({
          endpoint: `/api/directory/organization/${item.orgId}/unblock`,
          method: 'get',
          responseType: 'blob'
        })
        if (res.status === 200) {
          await this.addNotification({
            notificationStatus: 'success',
            notificationMessage: 'Организация разблокированна',
            timeout: 5000
          })
          item.blocked = false;
        } else {
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка блокировки данных',
            timeout: 5000
          })
        }
      }
      this.loadingBlockEnterData = false;
    }
  },
}
</script>

<style lang="sass" scoped>
.org-card-title
  display: flex
  flex-direction: row

  .org-card
    display: flex
    flex-direction: row
</style>